.skeleton {
    width: 100%;
    background: linear-gradient(to right, #4f4f4f 0%, #6b6b6b 50%, #4f4f4f 100%);
    background-size: 400% 400%;
    animation: pulse 1s ease-in-out infinite;
    min-height: 12px;
    max-height: 100%;
    padding: 2px;
    margin: 2px;
  }
  
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }